import { T } from "@/locales";

export default [
  {
    title: T("序号"),
    dataIndex: "index",
    key: "index",
    customRender: (value, item, index) => {
      return index + 1;
    },
  },
  {
    title: T("产品名称"),
    dataIndex: "material_name",
    ellipsis: true,
  },
  {
    title: T("产品编号"),
    dataIndex: "material_number",
    sorter: true,
  },
  {
    title: T("仓库编号"),
    dataIndex: "warehouse_number",
    sorter: true,
  },
  {
    title: T("仓库名称"),
    dataIndex: "warehouse_name",
  },
  {
    title: T("数量"),
    dataIndex: "quantity",
    sorter: true,
  },
];
